/* eslint-disable consistent-return */
import produce from 'immer'

const INITIAL_STATE = {
  sidebarShow: true,
  sidebarUnfoldable: false,
}

export default function sidebar(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'OPEN_CLOSE_SIDEBAR_': {
        draft.sidebarShow = !state.sidebarShow
        break
      }
      default:
    }
    switch (action.type) {
      case 'OPEN_CLOSE_SIDEBARUNFOLDABLE': {
        draft.sidebarUnfoldable = !state.sidebarUnfoldable
        break
      }
      default:
    }
  })
}
