export function OPEN_SIDEBAR() {
  return {
    type: 'OPEN_CLOSE_SIDEBAR_',
  }
}
export function OPEN_SIDEBARUNFOLDABLE() {
  return {
    type: 'OPEN_CLOSE_SIDEBARUNFOLDABLE',
  }
}
