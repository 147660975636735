import produce from 'immer'

const INITIAL_STATE = {
  clientes: [],
}

export default function boleto(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@SET_DADOS_LIST_CLIENTE_PAGANTES': {
        draft.clientes = action.payload.dados
        break
      }

      default:
    }
  })
}
