export function OPEN_SIDEBAR_CLIENT() {
  return {
    type: 'OPEN_SIDEBAR_CLIENT',
  }
}
export function CLOSE_SIDEBAR_CLIENT() {
  return {
    type: 'CLOSE_SIDEBAR_CLIENT',
  }
}
