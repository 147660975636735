

/* eslint-disable react/no-danger */
import React from 'react'
import { toast } from 'react-toastify'
import { Button, Modal, Space, Typography } from 'antd'
import RemoveBorderFooter, {
  ContentFooter,
  ContentModal,
  ContentTitle,
  TextTitle,
  BackgroundTransparent,
  AreaContent,
  CardContent,
  TextData,
  ButtonDelete,
} from './styles'

import { format, parseISO } from 'date-fns'

import { useAnnouncementUpdateStatusMutation } from '../../store/announcement/UpdateAnnouncement'

export const PopUpNewClient = ({ open, openModal, dataModal }) => {
  const { mutateAsync, isLoading, error, isSuccess } = useAnnouncementUpdateStatusMutation()

  const { Link } = Typography

  const onConfirmModal = () => {
    openModal(!open)
  }

  const updateAnnouncementStatus = async () => {
    await mutateAsync(dataModal._id)
  }

  React.useEffect(() => {
    if ((open, error)) {
      toast.error('Houve um error interno. Tente novamente mais tarde.')
    }
    if ((open, isSuccess)) {
      onConfirmModal()
    }
  }, [error, open, isSuccess])

  const { innerHeight } = window

  return (
    <>
      <RemoveBorderFooter />
      <Modal
        centered
        open={open}
        closable={false}
        onOk={onConfirmModal}
        onCancel={onConfirmModal}
        bodyStyle={{ height: innerHeight - 120, padding: '25px 50px' }}
        style={{ minWidth: '92vw' }}
        cancelText
        okText
        footer={[
          <Space
            key={null}
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              flexWrap: 'wrap',
            }}
          >
            <Button
              type="primary"
              onClick={onConfirmModal}
              block
              style={{ padding: '0 100px', maxWidth: 228 }}
            >
              Fechar
            </Button>
            {!dataModal?.viewedBy && (
              <ButtonDelete
                type="transparent"
                onClick={() => updateAnnouncementStatus()}
                disabled={isLoading || dataModal?.viewedBy}
                loading={isLoading}
              />
            )}
          </Space>,
        ]}
      >
        <ContentModal>
          <ContentTitle>
            <TextData style={{ fontSize: 16 }}>
              {format(parseISO(dataModal.createAt), 'dd/MM/yyyy - HH:mm')}
            </TextData>
            <TextTitle
              title={dataModal.title}
              ellipsis={{ rows: 2 }}
              style={{ fontSize: 24, margin: 0 }}
            >
              {dataModal.title}
            </TextTitle>
          </ContentTitle>
          <AreaContent>
            <CardContent
              id="content"
              style={{
                textAlign: 'justify',
                position: 'relative',
                overflowY: 'auto',
                height: '100%',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: dataModal.content ?? '',
                }}
              />
            </CardContent>
            <BackgroundTransparent />
          </AreaContent>
          <ContentFooter>
            <Space
              direction="vertical"
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <Link
                href={dataModal.link}
                target="_blank"
                style={{
                  textDecorationLine: 'underline',
                  fontSize: 16,
                  color: '#0593d2',
                }}
              >
                {dataModal.link}
              </Link>
            </Space>
          </ContentFooter>
        </ContentModal>
      </Modal>
    </>
  )
}
