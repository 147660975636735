import produce from 'immer'

const INITIAL_STATE = {
  token: {},
  url: '',
  login: {},
}

export default function fiscal(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@SET_DADOS_FISCAL': {
        draft.token = action.payload.dados
        break
      }

      default:
    }
    switch (action.type) {
      case '@SET_URL_FISCAL': {
        draft.url = action.payload.dados
        break
      }
      default:
    }
    switch (action.type) {
      case '@SET_LOGIN_FISCAL': {
        draft.login = action.payload.dados
        break
      }
      default:
    }
  })
}
