import { Button, Space, Typography } from 'antd'
import styled, { createGlobalStyle } from 'styled-components'

const { Title } = Typography

export default createGlobalStyle`
  .ant-modal-footer {
    border: none;
    padding: 0px 50px 25px 50px;
  }
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const TextData = styled.text``
export const TextTitle = styled(Title)`
  font-family: 'Nunito Sans';
`
export const ContentTitle = styled.div`
  text-align: center;
`
export const ContentFooter = styled.div``

export const BackgroundTransparent = styled.div`
  position: absolute;
  height: 60px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  left: 0;
  right: 0;
  bottom: 0;
`

export const AreaContent = styled.div`
    overflow: hidden;
    position: relative;
    margin-top: 12px;
    flex: 1;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-ms-overflow-style: none;
    &::scrollbar-width: none;
`

export const CardContent = styled.div`
    padding: 0px 0px 40px 0px;

    &::-webkit-scrollbar {
        display: none;
    }

    &::-ms-overflow-style: none;
    &::scrollbar-width: none;
`

export const ButtonDelete = styled(Button).attrs({
  children: 'Marcar como lido',
})`
  position: absolute;
  top: 0px;
  right: 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 250px;

  @media only screen and (max-width: 850px) {
    position: relative;
  }
`

export const SpaceButton = styled(Space)`
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;

  @media only screen and (max-width: 850px) {
    position: relative;
  }
`
