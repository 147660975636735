import { useMutation } from '@tanstack/react-query'
import api from '../../../services/api'
import queryClient from '../../queryClient'

const updateAnnouncementStatus = async (announcement_id: string) => {
  return await api.put(`/internalNotification/${announcement_id}`)
}

export const useAnnouncementUpdateStatusMutation = () => {
  return useMutation({
    mutationFn: (announcement_id: string) => {
      updateAnnouncementStatus(announcement_id)
    },
    onSuccess: () => queryClient.refetchQueries(['QUERY_ALL_ANNOUNCEMENT']),
  })
}
