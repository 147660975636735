import { useQuery } from '@tanstack/react-query'
import api from '../../../services/api'

export const getAllAnnouncement = async () => {
    const { data } = await api.get(`/internalNotification/all`)
    return data
}

export function useGetAllAnnouncement() {
  return useQuery(['QUERY_ALL_ANNOUNCEMENT'], () => getAllAnnouncement(), {
    refetchInterval: 1000 * 60 * 5, // 5 minutes
    refetchIntervalInBackground: true,
  })
}
