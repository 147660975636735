export const uploadAvatarRequest = (file) => ({
  type: '@cycle/UPLOAD_AVATAR_REQUEST',
  file,
})

export const uploadAvatarSuccess = (checkedFile) => ({
  type: '@cycle/UPLOAD_AVATAR_SUCCESS',
  checkedFile,
})
export const resetUploads = () => ({
  type: '@cycle/CANCEL_AVATAR_UPLOADS',
})
