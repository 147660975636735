import { takeLatest, call, put, all } from 'redux-saga/effects'
import api from '../../../services/api'
import { toast } from 'react-toastify'
import history from '../../../services/history'
import { signInSuccess, signFailure } from './actions'
import { CLOSE_SIDEBAR_CLIENT, OPEN_SIDEBAR_CLIENT } from '../userClient/actions'
import { message } from 'antd'

export function* signIn({ payload }: { payload: { email: string, password: string } }) {
  try {
    const { email, password } = payload

    const response = yield call(api.post, 'adm/sessions', {
      email,
      password,
    })

    localStorage.setItem('@usernameadmin:servemei', email)
    localStorage.setItem('@passwordadmin:servemei', password)

    if (response.data) {
      const { token, user } = response.data
      api.defaults.headers.Authorization = `Bearer ${token}`

      localStorage.setItem('@passwordadmin:id', user._id)

      yield put(signInSuccess(token, user))

      yield put(CLOSE_SIDEBAR_CLIENT())

      message.success('Visão de admin assumida.')
    }
  } catch (error: any) {
    message.error(error?.response?.data?.message || 'Falha na autenticação, verifique seus dados e tente novamente.')
    yield put(signFailure())
  }
}

export function* signInUser({ payload }: {payload: {id: string}}) {
  try {
    const { id } = payload

    const response = yield call(api.post, 'adm/sessions/user', { id: id })

    if (response.data) {
      const { token, user } = response.data
      api.defaults.headers.Authorization = `Bearer ${token}`

      yield put(signInSuccess(token, user))

      yield put(OPEN_SIDEBAR_CLIENT())

      toast.success(`Visão de ${user.email} assumida.`)
    }
  } catch (error: any) {
    toast.error('Falha na autenticação, verifique seus dados e tente novamente.')
  }
}

export function* signUp({ payload }: { payload: { email: string, password: string, confirmPassword: string } }) {
  try {
    const { email, password, confirmPassword } = payload

    const response = yield call(api.post, 'novo-usuario', {
      email,
      password,
      confirmPassword,
    })

    if (response.data.success) {
      history.push('/dashboard')
    }
  } catch (error: any) {
    toast.error('Falha na autenticação, verifique seus dados e tente novamente.')
    yield put(signFailure())
  }
}

export function setToken({ payload }: { payload: { auth: { token: string } }}) {
  if (!payload) return

  const { token } = payload.auth

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`
  }
}

export function signOut() {
  history.push('/')
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/SIGN_IN_REQUEST_USER', signInUser),
])
