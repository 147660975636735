import { Navigate, useLocation } from 'react-router-dom'
import { store } from '../store'

export { PrivateRoute }

function PrivateRoute({ children }: {
  children: JSX.Element | JSX.Element[]
}) {
  const { signed } = store.getState()?.auth
  const currentLocation = useLocation().pathname

  if (currentLocation.includes('linkpayments')) {
    return <Navigate to={currentLocation} />
  }

  if (!signed) {
    return <Navigate to="/login" />
  }

  return children
}
