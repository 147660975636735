/* eslint-disable consistent-return */
import produce from 'immer'

const INITIAL_STATE = {
  sidebarclienteShow: false,
}

export default function sidebarclient(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'OPEN_SIDEBAR_CLIENT': {
        draft.sidebarclienteShow = true
        break
      }
      default:
    }
    switch (action.type) {
      case 'CLOSE_SIDEBAR_CLIENT': {
        draft.sidebarclienteShow = false
        break
      }
      default:
    }
  })
}
