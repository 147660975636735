import axios from 'axios'
import { handleLogout } from './navigation'
import { endpoints } from '../.env'

const api = axios.create({
  baseURL: endpoints.api,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        handleLogout()
      }
    }
    return Promise.reject(error)
  },
)

export default api
