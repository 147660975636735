/* eslint-disable @typescript-eslint/no-non-null-assertion */
import 'react-app-polyfill/stable'
import 'core-js'
import 'react-toastify/dist/ReactToastify.min.css'
import React from 'react'
import { ConfigProvider } from 'antd'
import ptBR from 'antd/lib/locale/pt_BR'
import ReactDOM from 'react-dom/client'
import {App} from 'src/App'
import './index.css'
import 'antd/dist/antd.css'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import { QueryClientProvider } from '@tanstack/react-query'

import AnnouncementProvider from './providers/announcement'

import queryClient from './store/queryClient'


ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
   <QueryClientProvider client={queryClient}>
    <ConfigProvider locale={ptBR}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AnnouncementProvider>
            <App />
            <ToastContainer autoClose={3000} />
          </AnnouncementProvider>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </QueryClientProvider>,
  </React.StrictMode>,
)
