import { createStore, compose, applyMiddleware } from 'redux'
import { endpoints } from '../.env'

export default (reducers, middlewares) => {
  const enhancer =
    endpoints === 'development'
      ? compose(console.tron.createEnhancer(), applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares)

  return createStore(reducers, enhancer)
}
