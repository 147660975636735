

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { OPEN_SIDEBAR } from '../store/modules/sidebar/actions'
import { PopUpNewClient } from '../components/PopUpNewClient/PopUpNewClient'
import { useGetAllAnnouncement } from '../store/announcement/GetAllAnnouncement'

const AnnouncementProvider = ({ children }: any) => {
  const { sidebarShow } = useSelector((state: {sidebar: {sidebarShow: boolean}}) => state?.sidebar)
  const [dataModal, setDataModal] = useState({})
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const currentURL = window?.location?.href;

  const { data: announcemments } = useGetAllAnnouncement()

  const getAnnouncement = (announcement: any) => {
    const filter = announcement.find((el: { showPopup: boolean }) => el.showPopup)

    if (filter && !currentURL.includes('linkpayments')) {
      setDataModal(filter)
      setOpen(true)
    }
  }

  useEffect(() => {
    if (announcemments?.length) {
      getAnnouncement(announcemments)
    }
  }, [announcemments?.length])

  useEffect(() => {
    if (sidebarShow && announcemments?.length && open) {
      dispatch(OPEN_SIDEBAR())
    }
  }, [sidebarShow, announcemments?.length, open])

  const hiddenModal = (value: boolean) => {
    if (!sidebarShow) {
      dispatch(OPEN_SIDEBAR())
    }

    setOpen(value)
  }

  return (
    <>
      {open && <PopUpNewClient dataModal={dataModal} openModal={hiddenModal} open={open} />}
      {children}
    </>
  )
}

export default AnnouncementProvider
