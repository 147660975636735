import { combineReducers } from 'redux'

import auth from './auth/reducer'
import user from './user/reducer'
import uploads from './uploads/reducer'
import boleto from './boleto/reducer'
import fiscal from './fiscal/reducer'
import sidebar from './sidebar/reducer'
import sidebarclient from './userClient/reducer'

export default combineReducers({ auth, user, uploads, boleto, fiscal, sidebar, sidebarclient })
// uploads
