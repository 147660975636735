export function signInRequest(email: string, password: string) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  }
}

export function signInRequestUser({ id }) {
  return {
    type: '@auth/SIGN_IN_REQUEST_USER',
    payload: { id },
  }
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  }
}

export function signUpRequest(email, password, confirmPassword) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { email, password, confirmPassword },
  }
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  }
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  }
}
